<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator :height="2" class="loading-indicator" :color="'#ffd547'" />
      <NuxtPage />
    </NuxtLayout>
    
  </div>
</template>

<script lang="ts" setup>
const breakpoints = useBreakpoints();
const windowStore = useWindow();
const phoneNumbersStore = usePhoneNumbersStore();
const rootStore = useRootStore();
const headerStore = useHeaderStore();

const [{ headers }, { continents }, { primaryThemes }] = await Promise.all([
  useFindHeaders(),
  useFindContinents({
    populate: {
      countries: {
        preposition: true,
        sort: ['label:asc']
      },
      imageMenu: true
    }
  }),
  useFindThemes(),
  rootStore.fetchCommon()
]);
phoneNumbersStore.fetchPhoneData();
headerStore.setHeaders(headers);
headerStore.setHeaderDropdownsContinents(continents);
headerStore.setContextList(continents);
headerStore.setHeaderDropdownTheme(primaryThemes);

useHead({
  titleTemplate: '%s | Marco Vasco'
});

onMounted(() => {
  windowStore.changeWidth(window.innerWidth);
  windowStore.changeScroll(window.scrollY);
  window.addEventListener('resize', onResize);
  window.addEventListener('scroll', onScroll);
  onResize();
});
onBeforeUnmount(() => {
  windowStore.changeWidth(window.innerWidth);
  windowStore.changeScroll(window.scrollY);
  window.removeEventListener('resize', onResize);
  window.removeEventListener('scroll', onScroll);
});

useCDNHeaders(helper => {
  helper
    .addTags(['one', 'two'])
    .public()
    .setNumeric('maxAge', Number(process.env.CDN_TTL ?? 86400));
});

function onScroll(): void {
  windowStore.changeScroll(window.scrollY);
}

function onResize(): void {
  const mobileWidth = window
    .getComputedStyle(document.body)
    .getPropertyValue('--breakpoint-value-mobile')
    .replace('px', '');

  breakpoints.isMobile = window.innerWidth <= +mobileWidth;
}
</script>
<style lang="scss" scoped>
.loading-indicator {
  color: rgb(255 0 106);
}
</style>
