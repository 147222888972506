<template>
  <div class="slider-arrows" :class="{ small: size === 'small', [color]: true }">
    <div
      v-if="!which || which === 'left'"
      class="left"
      :class="{ disabled: modelValue?.isBeginning }"
    >
      <Icon class="icon" name="arrow-circle-plain-left" @click="shift('left')" />
    </div>
    <slot />
    <div
      v-if="!which || which === 'right'"
      class="right"
      :class="{ disabled: !modelValue?.params.loop && modelValue?.isEnd }"
    >
      <Icon class="icon" name="arrow-circle-plain-right" @click="shift('right')" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SwiperBinding } from '@/lib/types/swiper';

const {
  modelValue,
  size = 'big',
  color = 'transparent'
} = defineProps<{
  modelValue?: SwiperBinding;
  size?: 'big' | 'small';
  color?: 'white' | 'black' | 'transparent';
  which?: 'left' | 'right';
  wholeGroup?: boolean;
}>();

function shift(direction: 'left' | 'right'): void {
  if (!modelValue) {
    return;
  }
  if (direction === 'left') {
    modelValue.slidePrev();
  } else {
    modelValue.slideNext();
  }
}
</script>

<style lang="scss" scoped>
@use '$/colors.scss';
@use '$/mouse.scss';
@use '$/shadows.scss';
@use '$/z-index.scss';

.slider-arrows {
  gap: 40px;

  .left,
  .right {
    @include mouse.clickable-opacity($disabled-diff: -1);
  }

  &.small {
    .left,
    .right {
      .icon:deep(svg) {
        width: 32px;
      }
    }
  }

  &.group-centered {
    display: contents;

    .left,
    .right {
      position: absolute;
      z-index: z-index.$any-on-top;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &.black,
  &.white {
    .left,
    .right {
      border-radius: 50%;
      box-shadow: shadows.$bubble-map;
    }
  }

  &.black:deep(svg) {
    circle {
      fill: colors.$black;
    }

    path {
      fill: white;
    }
  }

  &.transparent {
    .left,
    .right {
      &.disabled {
        opacity: 0.25;
      }
    }

    :deep(svg) {
      circle {
        fill: none;
      }
    }
  }
}
</style>
