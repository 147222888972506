<template>
  <NuxtLayout>
    <div id="page404">
      <div class="content">
        <SectionDescription
          title="Page non trouvée"
          description="La page que vous recherchez n’existe pas ou n’existe plus.
         

Pour repartir sur le bon chemin, nous vous invitons à découvrir nos suggestions de voyages 100% personnalisables à votre rythme et selon vos envies :"
          description-expanded
        />
        <div class="cta">
          <NuxtLink :to="'/marco-vasco-avis/'">Découvrez tous nos avis clients !</NuxtLink>
        </div>
      </div>
      <SectionInspiration title="Découvrez nos thématiques" :top-themes="primaryThemes" />
    </div>
  </NuxtLayout>
</template>
  
<script lang="ts" setup>
const phoneNumbersStore = usePhoneNumbersStore();
const rootStore = useRootStore();
const headerStore = useHeaderStore();
const footerStore = useFooterStore();

// Fetch all global data needed around the app and store them in the global data store
useAsyncData('root', async () => {
  const promises = await Promise.allSettled([
    rootStore.fetchCommon(),
    footerStore.fetchFooter(),
    phoneNumbersStore.fetchPhoneData()
  ]);

  for (const promise of promises) {
    if (promise.status === 'rejected') {
      console.error('Rejected promise:', promise.reason);
    }
  }
  const [common, footer] = promises;

  return {
    common: common.status === 'fulfilled' ? common.value : null,
    footer: footer.status === 'fulfilled' ? footer.value : null
  };
});

const [{ headers }, { continents }, { primaryThemes }] = await Promise.all([
  useFindHeaders(),
  useFindContinents({
    populate: {
      countries: {
        sort: ['label:asc']
      },
      imageMenu: true
    }
  }),
  useFindThemes()
]);
headerStore.setHeaders(headers);
headerStore.setHeaderDropdownsContinents(continents);
headerStore.setHeaderDropdownTheme(primaryThemes);

useHead({
  titleTemplate: '%s | Marco Vasco'
});
</script>
  
<style lang="scss" scoped>
@use '$/metrics.scss';
@use '$/button.scss';
@use '$/colors';

#page404 {
  display: flex;
  flex-direction: column;
  margin: 30px 10px;
}

.content {
  display: flex;
  flex-direction: column;

  width: 90%;
  max-width: metrics.$inner-width;
  margin: auto;

  & .cta {
    @include button.plain($color: colors.$primary-yellow);

    width: fit-content;
  }
}
</style>