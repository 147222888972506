import { defineStore } from 'pinia';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { MvCommon } from '@/lib/strapi-types/MvCommon';
import { Strapi4ResponseSingle } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

type ExtendedMvCommonAttributes = MvCommon['attributes'] & {
  phoneNumberAventure?: string;
  phoneNumberConciergerie?: string;
  phoneNumberDefault?: string;
  phoneNumberNoces?: string;
};

export const useRootStore = defineStore('root', {
  state: () => ({
    common: null as ExtendedMvCommonAttributes | null
  }),
  getters: {
    getCommon: state => state.common
  },
  actions: {
    async fetchCommon() {
      const parsedQuery = stringify({
        populate: [
          'socials',
          'responsabilities',
          'newsletter.icon',
          'expertsSection.link',
          'expertsSection.CTA',
          'locationsSection',
          'responsibleSection.CTA',
          'responsibleSection.image',
          'journeySuggestionsSection'
        ]
      });

      const { data } = await useFetchWithHead<Strapi4ResponseSingle<MvCommon['attributes']>>(
        `${useCompleteUrl(DevBackEndpoints.COMMON)}?${parsedQuery}`
      );

      const commonData = data.value;

      this.common = {
        socials: commonData?.data.attributes.socials ?? [],
        responsabilities: commonData?.data.attributes.responsabilities ?? [],
        newsletter: commonData?.data.attributes.newsletter,
        expertsSection: commonData?.data.attributes.expertsSection,
        locationsSection: commonData?.data.attributes.locationsSection,
        responsibleSection: commonData?.data.attributes.responsibleSection,
        journeySuggestionsSection: commonData?.data.attributes.journeySuggestionsSection,
        phoneNumberAventure: commonData?.data.attributes.phoneNumberAventure,
        phoneNumberConciergerie: commonData?.data.attributes.phoneNumberConciergerie,
        phoneNumberDefault: commonData?.data.attributes.phoneNumberDefault,
        phoneNumberNoces: commonData?.data.attributes.phoneNumberNoces
      };
      return this.common;
    }
  }
});
