<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    v-if="icon && name"
    :class="{ fill, stroke, width, height }"
    :style="`--i-fill: ${fill}; --i-stroke: ${stroke}; --i-width: ${width}; --i-height: ${height}; --i-max-width: ${maxWidth}; --i-max-height: ${maxHeight};`"
    class="icon"
    v-html="icon"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<script lang="ts" setup>
const { name, fill, stroke, width, height } = defineProps<{
  name: string;
  fill?: string;
  stroke?: string;
  height?: string;
  width?: string;
  maxWidth?: string;
  maxHeight?: string;
}>();

let icon = $ref<string>('');
async function getIcon() {
  const iconsImport = import.meta.glob('assets/icons/**/**.svg', {
    as: 'raw',
    eager: false
  });
  try {
    const iconImport = iconsImport[`/assets/icons/${name}.svg`];
    if (iconImport) {
      icon = await iconImport();
    }
  } catch {
    const placeholderImport = iconsImport[`/assets/icons/placeholder.svg`];
    if (placeholderImport) {
      icon = await placeholderImport();
    }
  }
}

await getIcon();

watch(() => name, getIcon);
</script>

<style lang="scss" scoped>
.icon {
  align-items: center;
  justify-content: center;

  &:deep(svg) {
    max-width: var(--i-max-width) !important;
    max-height: var(--i-max-height) !important;
    vertical-align: baseline !important;
  }

  &.width:deep(svg) {
    width: var(--i-width) !important;
  }

  &.height:deep(svg) {
    height: var(--i-height) !important;
  }

  &.fill:deep(svg) {
    fill: var(--i-fill) !important;

    & > * {
      fill: inherit !important;
    }
  }

  &.stroke:deep(svg) {
    stroke: var(--i-stroke) !important;

    & > * {
      stroke: inherit !important;
    }
  }
}
</style>
