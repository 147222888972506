import VueDOMPurifyHTML from 'vue-dompurify-html';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(VueDOMPurifyHTML, {
    default: {
      ADD_TAGS: ['iframe']
    },
    namedConfigurations: {
      svg: {
        USE_PROFILES: { svg: true }
      },
      markdown: {
        ADD_ATTR: ['target']
      }
    }
  });
});
