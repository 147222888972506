import { MvCountry } from '@/lib/strapi-types/MvCountry';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface UseFindCountriesProps {
  filters?: Record<string, unknown>;
  populate?: string[];
}

export async function useFindCountries({
  filters = {},
  populate = []
}: UseFindCountriesProps = {}) {
  const defaultPopulate = [''];
  const allPopulate = [...defaultPopulate, ...populate];

  const defaultFilters: Record<string, unknown> = {};
  const queryFilters: Record<string, unknown> = { ...filters, ...defaultFilters };

  const parsedQuery = stringify(
    {
      populate: allPopulate,
      filters: queryFilters,
      pagination: { start: 0, limit: 200 },
      sort: ['slug:asc']
    },
    { arrayFormat: 'brackets' }
  );

  const { data } = await useFetchWithHead<Strapi4ResponseMany<MvCountry['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.COUNTRIES)}?${parsedQuery}`
  );

  return { countries: data.value?.data };
}
