import { Strapi4ResponseSingle } from '@nuxtjs/strapi/dist/runtime/types';
import { defineStore } from 'pinia';
import { stringify } from 'qs';

import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { MvFooter } from '@/lib/strapi-types/MvFooter';
import { FooterSection } from '@/lib/strapi-types/components/FooterSection';
import { FooterOffice } from '@/lib/strapi-types/components/FooterOffice';
import { FooterLink } from '@/lib/strapi-types/components/FooterLink';

export const useFooterStore = defineStore('footer', {
  state: () => ({
    sections: [] as FooterSection[],
    offices: [] as FooterOffice[],
    links: [] as FooterLink[],
    practicalInformations: [] as FooterLink[]
  }),

  actions: {
    async fetchFooter() {
      const parsedQuery = stringify({
        populate: [
          'sections',
          'sections.links',
          'offices',
          'offices.links',
          'links',
          'practicalInformations'
        ]
      });
      const { data: footer } = await useFetchWithHead<
        Strapi4ResponseSingle<MvFooter['attributes']>
      >(`${useCompleteUrl(DevBackEndpoints.FOOTER)}?${parsedQuery}`);
      this.sections = footer.value?.data.attributes.sections ?? [];
      this.offices = footer.value?.data.attributes.offices ?? [];
      this.links = footer.value?.data.attributes.links ?? [];
      this.practicalInformations = footer.value?.data.attributes.practicalInformations ?? [];
    }
  }
});
