import { MarkdownResult, renderMarkdown } from '@/lib/markdown';
import { LOWERCASEMONTHS, MONTHS } from './constants';

export function strip(s: string) {
  return s.replaceAll(/\n */g, ' ');
}
export function removeAllSegments(url: string): string {
  return url.replace(/\//g, '');
}

export function getSanitizedAndCroppedText(
  text: string,
  maxLength: number,
  isCropEnabled: boolean
) {
  const isCropNeeded = text.length >= maxLength;
  return isCropNeeded && isCropEnabled ? text.substring(0, maxLength - 3) + '...' : text;
}

export function filterOutNullOrUndefined<T>(item: T | undefined): item is T {
  return item !== null && item !== undefined;
}

export function getRenderedMarkdown(content: string, expanded: boolean): MarkdownResult {
  const MAX_CHARS = 400;
  const MAX_CHARS_MOBILE = 122;
  const breakpoints = useBreakpoints();

  const maxChars = breakpoints.isMobile ? MAX_CHARS_MOBILE : MAX_CHARS;
  if (content.length > maxChars) {
    return renderMarkdown(content, expanded ? 0 : maxChars);
  }
  return renderMarkdown(content);
}

export function goTo(
  childId: string,
  parentId: string,
  leftMargin: number,
  topMargin?: number
): void {
  !topMargin && (topMargin = 0);
  !leftMargin && (leftMargin = 0);

  const element = document.getElementById(parentId);

  const left = document.getElementById(childId)?.offsetLeft;
  const top = document.getElementById(childId)?.offsetTop;

  if (!element) {
    return;
  }
  if (left && top) {
    element.scrollTo(left - leftMargin, top - topMargin);
  }
}

export function kebabCase(string: string) {
  return string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
}

export function firstLetterMajor(string: string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
}

export function firstLetterMinor(string: string) {
  return string && string.charAt(0).toLowerCase() + string.slice(1);
}

export function removeAccents(string: string) {
  return string.normalize('NFD').replace(/[\u0300-\u036F]/g, '');
}

export function capitalize(str: string) {
  const words = str.split(/[\s-]+/);
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
}

export function formatDate(date: string) {
  return Intl.DateTimeFormat('fr', { dateStyle: 'long' }).format(new Date(date));
}

export function replacePlaceholders(
  placeholder: string,
  values: Record<string, string | number | boolean>
) {
  return placeholder.replace(/{{\s*([^{}]+)\s*}}/g, (match, key) =>
    values[key] ? String(values[key]) : match
  );
}

export function transformerDate(dateStr: string) {
  const [année, moisNum, jour] = dateStr.split('-');
  return `${parseInt(jour ?? '')} ${LOWERCASEMONTHS[parseInt(moisNum ?? '') - 1]} ${année}`;
}

export function transformerDateReview(dateStr: string) {
  const [année, moisNum, jour] = dateStr.split('-');
  return `${parseInt(jour ?? '')} ${MONTHS[parseInt(moisNum ?? '')]} ${année}`;
}
