<template>
  <div class="points">
    <div
      v-for="(_, i) in points"
      :key="i"
      class="point"
      :class="{
        selected:
          (index === i && !modelValue?.isEnd) || (modelValue?.isEnd && i === pointsCount - 1)
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import { SwiperBinding } from '@/lib/types/swiper';

const { modelValue, wholeGroup = false } = defineProps<{
  modelValue?: SwiperBinding;
  current?: number;
  max?: number;
  wholeGroup?: boolean;
}>();

const pointsCount = $computed(() => {
  if (!modelValue) {
    return 1;
  }

  return modelValue.snapGrid.length;
});

const index = $computed(() => {
  if (!modelValue) {
    return 0;
  }

  const index = modelValue.realIndex;
  return wholeGroup ? Math.floor(index / modelValue.actualSlidesPerGroup) : index;
});
const points = $computed(() => Array(pointsCount));
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '$/colors.scss';

.points {
  gap: 12px;
  justify-content: center;

  .point {
    $size: 8px;

    width: $size;
    height: $size;

    background-color: colors.$grey-500;
    border-radius: math.div($size, 2);

    transition-duration: 0.2s;
    transition-property: width, background-color;

    &.selected {
      width: 32px;
      background-color: colors.$primary-black;
    }
  }
}
</style>
