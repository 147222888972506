<template>
  <Swiper class="swiper" v-bind="props" @swiper="onSwiper">
    <slot />
  </Swiper>
</template>

<script lang="ts" setup>
import type { Swiper } from 'swiper';
// import type { Swiper as SwiperComponent } from 'swiper/vue';

import { SwiperBinding } from '@/lib/types/swiper';

// Sadly this type doesn't work, so we have to use any
// Parameters<Exclude<typeof SwiperComponent['setup'], undefined>>[0]
const props = defineProps<{ [key: string]: any }>();
const emit = defineEmits<{ (event: 'update:modelValue', swiper: SwiperBinding): void }>();

function onSwiper(swiper: Swiper): void {
  const { slidesPerView } = swiper.params;

  const binding = swiper as SwiperBinding;
  binding.actualSlidesPerGroup = typeof slidesPerView !== 'number' ? 1 : slidesPerView;
  binding.slideCount = swiper.slides.filter(
    s => !s.classList.contains('swiper-slide-duplicate')
  ).length;

  emit('update:modelValue', binding);
}
</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;

  :deep(.swiper-slide) {
    width: fit-content;
  }
}
</style>
