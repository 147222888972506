import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_iGLjBbor9q from "/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_BqLkNUef68 from "/app/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import swiper_wnFZ5tA1pM from "/app/.nuxt/swiper.mjs";
import strapi_plugin_35gTOGMBRa from "/app/node_modules/@nuxtjs/strapi/dist/runtime/strapi.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import appconsent_6fFPZxPYDE from "/app/plugins/appconsent.ts";
import datalayer_G9Aj5JlukA from "/app/plugins/datalayer.ts";
import vue_dompurify_html_ER8SW1XOdk from "/app/plugins/vue-dompurify-html.ts";
import vue_gtag_client_CYt6toK02h from "/app/plugins/vue-gtag.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_iGLjBbor9q,
  plugin_BqLkNUef68,
  swiper_wnFZ5tA1pM,
  strapi_plugin_35gTOGMBRa,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  appconsent_6fFPZxPYDE,
  datalayer_G9Aj5JlukA,
  vue_dompurify_html_ER8SW1XOdk,
  vue_gtag_client_CYt6toK02h
]