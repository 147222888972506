/* eslint-disable camelcase */
import { useRouter, useRoute } from 'vue-router';

export function useDataLayer() {
  const route = useRoute();
  const router = useRouter();
  let userAgent = '';

  if (process.client) {
    userAgent = navigator?.userAgent.toLowerCase();
  }

  function getDeviceType() {
    if (/mobile|android|touch|webos|hpwos/i.test(userAgent)) {
      return 'mobile';
    } else if (/tablet|ipad|playbook|silk/i.test(userAgent)) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  function getOrientation() {
    if (window.matchMedia('(orientation: portrait)').matches) {
      return 'portrait';
    } else if (window.matchMedia('(orientation: landscape)').matches) {
      return 'landscape';
    }
  }

  const pushToDataLayer = (data: Record<string, any>) => {
    if (process.client) {
      window.dataLayer = window.dataLayer ?? [];
      window.dataLayer.push(data);
    }
  };

  const updateDataLayer = () => {
    const pathSegments = route.fullPath.split('/').filter(Boolean);

    const BU_level1 = pathSegments[0] ?? 'MarcoVasco';
    const BU_level2 = pathSegments[1] ?? 'planetveo';
    const BU_level3 = pathSegments[1] ?? 'planetveo';
    const brand = useRuntimeConfig().public.brand;
    const environement = useRuntimeConfig().public.environement;

    pushToDataLayer({
      BU_siteName: brand,
      BU_environnement: environement,
      BU_level1,
      BU_level2,
      BU_level3,
      BU_theme: typeof route.name === 'string' ? route.name.split('-').pop() : '',
      BU_userAgent: userAgent,
      BU_websiteVersion: getDeviceType(),
      BU_orientation: getOrientation()
    });
  };

  watch(router.currentRoute, () => {
    updateDataLayer();
  });

  return {
    pushToDataLayer,
    updateDataLayer
  };
}
