export function useFetchWithHead<T>(url: string, options?: Record<string, any>, key?: string) {
  const nuxtApp = useNuxtApp();
  options = options ?? {};

  options.headers = {
    ...options.headers,
    'x-origin': useRuntimeConfig().public.baseURL
  };

  return useFetch<T>(url, {
    ...options,
    key: key ?? url,
    cache: 'default',
    transform(input) {
      if (typeof input === 'object' && input !== null) {
        return {
          ...input,
          fetchedAt: new Date()
        };
      }

      return input;
    },
    getCachedData(key) {
      const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key];

      if (!data) {
        return;
      }

      const expirationDate = new Date(data.fetchedAt);

      expirationDate.setTime(expirationDate.getTime() + 5 * 60 * 1000); // 5 minutes
      const isExpired = expirationDate.getTime() < Date.now();

      if (isExpired) {
        return;
      }

      return data;
    }
  });
}
