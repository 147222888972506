import Continents from './types/enums/continents';

import { Season } from '@/lib/types/models/common';

export const JOURNEY_TABS = [
  'Programme',
  'Activités suggérées',
  'Hôtels',
  'Bon à savoir',
  'Inclus'
];

export const SORT_JOURNEY = [
  { key: 'price', label: 'Par prix' },
  { key: 'days', label: 'Par durée' },
  { key: 'averageRating', label: 'Par avis' }
];

export const CONTINENT_NAMES: Record<string, string> = {
  [Continents.AFRICA]: 'Afrique & Océan Indien',
  [Continents.NORTH_AMERICA]: 'Amerique du Nord & Caraïbes',
  [Continents.SOUTH_AMERICA]: 'Amérique Latine',
  [Continents.ASIA]: 'Asie & Moyen-Orient',
  [Continents.EUROPE]: 'Europe',
  [Continents.OCEANIA]: 'Océanie & Pacifique'
};

export const CONTINENT_ICONS: Record<string, string> = {
  [Continents.AFRICA]: 'picto-afrique',
  [Continents.NORTH_AMERICA]: 'picto-amerique-du-nord',
  [Continents.SOUTH_AMERICA]: 'picto-amerique-latine',
  [Continents.ASIA]: 'picto-asie',
  [Continents.EUROPE]: 'picto-europe',
  [Continents.OCEANIA]: 'picto-oceanie-et-iles'
};

export const MONTHS = [
  '',
  'Jan.',
  'Fév.',
  'Mars',
  'Avr',
  'Mai',
  'Juin',
  'Juil.',
  'Août',
  'Sep.',
  'Oct.',
  'Nov.',
  'Déc.'
];

export const LOWERCASEMONTHS = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'août',
  'septembre',
  'octobre',
  'novembre',
  'décembre'
];

export const SEASONS: Season[] = [
  {
    id: 'winter',
    name: 'Hiver',
    icon: 'snowflake'
  },
  {
    id: 'spring',
    name: 'Printemps',
    icon: 'flower'
  },
  {
    id: 'summer',
    name: 'Été',
    icon: 'sun'
  },
  {
    id: 'autumn',
    name: 'Automne',
    icon: 'leaf'
  }
];

export const PRICES = {
  name: 'Prix',
  filterValues: [
    {
      name: 'De 500 à 2500 € ',
      value: ['500', '2500']
    },
    {
      name: 'De 2500 à 7500 €',
      value: ['2500', '7500']
    },
    {
      name: 'De 7500 à 15000 € ',
      value: ['7500', '15000']
    },
    {
      name: 'De 15000 à 45000 € ',
      value: ['15000', '45000']
    }
  ]
};

export const DURATIONS = {
  name: 'Durée',
  filterValues: [
    {
      name: 'De 2 jours à 1 semaine ',
      value: ['2', '7']
    },
    {
      name: 'De 1 à 2 semaines ',
      value: ['8', '15']
    },
    {
      name: 'De 2 à 3 semaines ',
      value: ['16', '21']
    },
    {
      name: 'De 3 à 4 semaines ',
      value: ['22', '29']
    },
    {
      name: 'De 4 à 8 semaines ',
      value: ['30', '58']
    }
  ]
};

export const BLOCK_JOURNEY_FILTERS = [PRICES, DURATIONS];

export const QUOTATION_STEPS = [
  {
    name: 'Votre voyage',
    value: 1
  },
  {
    name: 'Mes coordonnées',
    value: 2
  },
  {
    name: 'Envoi',
    value: 3
  }
] as const;

export enum QuotationContext {
  DEFAULT = 'default',
  HEROSEA = 'herosea'
}

export const QUOTATION_CHECK = [
  { name: 'Mes dates sont flexibles', value: 'flexible' },
  { name: "J'ai déjà mes vols", value: 'vols' },
  { name: 'Voyage de noces', value: 'noce' }
];

export const YEARS = ['2018', '2019', '2020', '2021', '2022', '2023', '2024'];

export const BREADCRUMB = {
  home: 'Accueil'
};

export const BUTTON_LABELS_GIR = {
  disponible: 'Réserver',
  complet: 'Complet',
  garantie: 'Garantie'
} as const;
