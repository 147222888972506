enum Continents {
  ASIA = 'asie',
  EUROPE = 'europe',
  NORTH_AMERICA = 'amerique-du-nord',
  SOUTH_AMERICA = 'amerique-latine',
  AFRICA = 'afrique',
  OCEANIA = 'oceanie-et-iles'
}

export default Continents;
