import { MvContinent } from '@/lib/strapi-types/MvContinent';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface PopulateObject {
  [key: string]:
    | string[]
    | PopulateObject
    | boolean
    | {
        [key: string]: unknown;
      };
}

interface UseFindContinentsProps {
  filters?: Record<string, unknown>;
  populate?: string[] | PopulateObject;
}

const defaultPopulate = ['media', 'tabs', 'countries'];

/**
 * Processes the populate parameter and returns the appropriate value.
 * If the populate parameter is an array, it merges it with the defaultPopulate array.
 * If the populate parameter is an object, it merges it with the defaultPopulate object.
 * If the populate parameter is not provided or is of an invalid type, it returns the defaultPopulate array.
 * @param populate - The populate parameter to process.
 * @returns The processed populate value.
 */
const processPopulate = (populate?: string[] | PopulateObject) => {
  if (Array.isArray(populate)) {
    return [...defaultPopulate, ...populate];
  } else if (typeof populate === 'object' && populate !== null) {
    return {
      ...defaultPopulate.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
      ...populate
    };
  } else {
    return defaultPopulate;
  }
};

export async function useFindContinents({
  filters = {},
  populate = []
}: UseFindContinentsProps = {}) {
  const allPopulate = processPopulate(populate);

  const parsedQuery = stringify(
    {
      populate: allPopulate,
      filters
    },
    { arrayFormat: 'brackets' }
  );

  const { data } = await useFetchWithHead<Strapi4ResponseMany<MvContinent['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.CONTINENTS)}?${parsedQuery}`
  );

  return { continents: data.value?.data };
}
