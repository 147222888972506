<template>
  <div class="section-title">
    <Icon class="logo" name="logo" />
    <h2 class="text">
      <slot />
    </h2>
  </div>
</template>

<style lang="scss" scoped>
@use '$/breakpoints.scss';

.section-title {
  gap: 16px;
  align-items: center;

  @include breakpoints.mobile() {
    gap: 12px;
  }

  .logo:deep(svg) {
    @include breakpoints.mobile() {
      width: 40px;
      height: 40px;
    }
  }

  .text {
    font-size: 30px;
    font-weight: 800;

    @include breakpoints.mobile() {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
</style>
