<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="description-container">
    <div class="description">
      <SectionTitle>{{ title }}</SectionTitle>
      <div v-if="descriptionMarkdown" class="inner">
        <div
          v-if="commonDescriptionMarkdown"
          class="content"
          v-html="commonDescriptionMarkdown.rendered"
        />
        <div class="content" v-html="descriptionMarkdown.rendered" />
        <div
          v-if="(descriptionMarkdown.cropped || expanded) && !descriptionExpanded"
          class="more"
          @click="expanded = !expanded"
        >
          {{ expanded ? 'Lire moins' : 'Lire la suite' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getRenderedMarkdown } from '@/lib/utils';

const {
  description,
  commonDescription,
  descriptionExpanded = false
} = defineProps<{
  title: string;
  description?: string;
  commonDescription?: string;
  descriptionExpanded?: boolean;
}>();

const expanded = $ref(false);

const commonDescriptionMarkdown = $computed(() =>
  commonDescription ? getRenderedMarkdown(`${commonDescription}`, expanded) : undefined
);

const descriptionMarkdown = $computed(() =>
  description ? getRenderedMarkdown(`${description}`, descriptionExpanded || expanded) : undefined
);
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/metrics.scss';
@use '$/mouse.scss';

.description-container {
  justify-content: center;
  margin: 40px 0;

  @include breakpoints.mobile() {
    margin: 32px 0;
  }

  .description {
    flex-direction: column;
    gap: 20px;
    width: metrics.$inner-width;

    @include breakpoints.mobile() {
      width: 100vw;
      padding-right: 16px;
      padding-left: 16px;
    }

    .inner {
      flex-direction: column;
      gap: 8px;

      .content {
        flex-direction: column;
        gap: 16px;
        font-size: 18px;
        text-align: justify;

        @include breakpoints.mobile() {
          font-size: 16px;
        }

        :deep(p) {
          line-height: 24px;
        }

        :deep(ul),
        :deep(ol) {
          margin-left: 24px;

          @include breakpoints.mobile() {
            margin-left: 18px;
          }
        }
      }

      .more,
      .content:deep(a) {
        @include mouse.clickable-lightness();

        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }
}
</style>
