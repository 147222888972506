import { defineStore } from 'pinia';

import { MvHeader } from '@/lib/strapi-types/MvHeader';
import { HeaderBand } from '@/lib/strapi-types/components/HeaderBand';
import { Header } from '@/lib/strapi-types/components/Header';

import type {
  CountriesHeader as HeaderDropdownTheme,
  DestinationsDropdownCategory as HeaderDropdownContinent,
  ListContext
} from '@/lib/types/models/header';
import { firstLetterMajor } from '@/lib/utils';
import { MvContinent } from '@/lib/strapi-types/MvContinent';
import { MvTheme } from '@/lib/strapi-types/MvTheme';
import { Link } from '@/lib/strapi-types/components/Link';

export const useHeaderStore = defineStore('header', {
  state: () => ({
    header: {} as Header,
    headerBand: {} as HeaderBand,
    headerDropdowncontinents: [] as HeaderDropdownContinent[],
    headerDropdownThemes: [] as HeaderDropdownTheme[],
    navThematics: [] as Link[],
    listContext: {} as ListContext
  }),

  actions: {
    setHeaders(headers?: MvHeader) {
      this.header = headers?.attributes.header ?? ({} as Header);
      this.headerBand = headers?.attributes.headerBand ?? ({} as HeaderBand);
      this.navThematics = headers?.attributes.header.navThematics ?? [];
    },
    setHeaderDropdownsContinents(continents?: MvContinent[]) {
      const headerDropdowncontinents: HeaderDropdownContinent[] =
        continents?.map(continent => {
          return {
            image: {
              url:
                continent.attributes.imageMenu?.data?.attributes.url ??
                continent.attributes.imageMenu_path,
              title: continent.attributes.imageMenu?.data?.attributes.alternativeText ?? ''
            },
            title: firstLetterMajor(continent.attributes.name),
            slug: continent.attributes.slug,
            countries:
              continent.attributes.countries?.data.map(country => ({
                slug: country.attributes.slug,
                name: (country.attributes.label && firstLetterMajor(country.attributes.label)) ?? ''
              })) ?? []
          };
        }) ?? [];
      this.headerDropdowncontinents = headerDropdowncontinents;
    },

    setContextList(continents?: MvContinent[]) {
      continents?.forEach(continent => {
        const continentName =
          (continent.attributes.name && firstLetterMajor(continent.attributes.name)) ?? '';
        const continentSlug = continent.attributes.slug;
        continent.attributes.countries?.data.forEach(country => {
          const countryName =
            (country.attributes.label && firstLetterMajor(country.attributes.label)) ?? '';
          const countrySlug = country.attributes.slug ?? '';
          const countryPreposition = country.attributes.preposition ?? 'au';
          this.listContext[countrySlug] = {
            continent: {
              name: continentName,
              slug: continentSlug
            },
            name: countryName,
            slug: countrySlug,
            preposition: countryPreposition
          };
        });
      });
    },

    setHeaderDropdownTheme(primaryThemes: MvTheme[]) {
      const themes: HeaderDropdownTheme[] =
        primaryThemes.map(theme => ({
          slug: theme.attributes.slug,
          name: theme.attributes.label
        })) ?? [];

      this.headerDropdownThemes = themes;
    }
  }
});
