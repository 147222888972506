import { MvCountry } from '@/lib/strapi-types/MvCountry';
import { defineStore } from 'pinia';
import { useFindCountries } from '@/composables/countries/useFindCountries';
import { useRootStore } from '@/composables/stores/useRootStore';
import { storeToRefs } from 'pinia';
interface CountryPhoneNumber {
  slug: string;
  phoneNumber?: string;
}

interface DefaultValues {
  phoneNumberAventure?: string;
  phoneNumberConciergerie?: string;
  phoneNumberDefault?: string;
  phoneNumberNoces?: string;
  countryPhoneNumbers: CountryPhoneNumber[];
}

export const usePhoneNumbersStore = defineStore('phoneNumbers', {
  state: (): DefaultValues => ({
    countryPhoneNumbers: [],
    phoneNumberAventure: undefined,
    phoneNumberConciergerie: undefined,
    phoneNumberDefault: undefined,
    phoneNumberNoces: undefined
  }),

  actions: {
    async fetchPhoneData() {
      const [{ countries }] = await Promise.all([
        useFindCountries().then(res => res as { countries: MvCountry[] })
      ]);

      const rootStore = useRootStore();
      const { common } = storeToRefs(rootStore);
      const defaultValues = common.value;

      if (countries) {
        this.countryPhoneNumbers = countries.map(country => ({
          slug: country.attributes.slug,
          phoneNumber: country.attributes.phoneNumber ?? undefined
        }));
      }

      if (defaultValues) {
        this.phoneNumberAventure = defaultValues?.phoneNumberAventure;
        this.phoneNumberConciergerie = defaultValues?.phoneNumberConciergerie;
        this.phoneNumberDefault = defaultValues?.phoneNumberDefault;
        this.phoneNumberNoces = defaultValues?.phoneNumberNoces;
      }
    },
    getPhoneCountry(countrySlug?: string): string | undefined {
      const phoneSelected = this.countryPhoneNumbers.find(
        countryPhone => countryPhone.slug === countrySlug
      );
      return phoneSelected?.phoneNumber ?? this.phoneNumberDefault;
    }
  }
});
