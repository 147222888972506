// plugins/appconsent.ts
export default () => {
  const configScript = document.createElement('script');
  configScript.type = 'text/javascript';
  configScript.innerHTML = `
      const configSFBXAppConsent = {
        appKey: '${useRuntimeConfig().public.appConsentKey}'
      }
    `;
  document.head.appendChild(configScript);

  const coreScript = document.createElement('script');
  coreScript.src = 'https://cdn.appconsent.io/tcf2-clear/current/core.bundle.js';
  coreScript.defer = true;
  coreScript.async = true;
  document.head.appendChild(coreScript);

  const gtagScript = document.createElement('script');
  gtagScript.type = 'text/javascript';
  gtagScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      (window.adsbygoogle = window.adsbygoogle || []).pauseAdRequests = 1;
      gtag('consent', 'default', {
        'analytics_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'functionality_storage': 'granted',
        'security_storage': 'granted',
        'personalization_storage': 'denied',
        'ad_storage': 'denied',
        'wait_for_update': 500
      });
    `;
  document.head.appendChild(gtagScript);
};
