<template>
  <NuxtLink :href="`${urlLink}`">
    <div
      class="card"
      :style="`background: url(${img(
        `${strapiURL}${urlBackgroundImage}`,
        {}
      )}) center / cover; width:${width}px`"
    >
      <div class="background-gradient"></div>
      <div class="card__content content">
        <Icon
          v-if="icon"
          :name="icon"
          class="content__icon"
          fill="white"
          height="60px"
          width="60px"
        />
        <div class="content__label">{{ label }}</div>
        <div v-if="isLearnMore" class="content">
          <div class="content__separate"></div>
          <div class="content__link">En savoir plus</div>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { MvIcon } from '@/lib/types/models/mvIcon';
const {
  label = 'LABEL MANQUANT',
  urlLink = '#',
  urlBackgroundImage = undefined, // Definir une image par defaut ?
  isLearnMore = true,

  icon = undefined
} = defineProps<{
  label?: string;
  urlLink?: string;
  urlBackgroundImage?: string;
  isLearnMore?: boolean;
  width?: number;
  icon?: MvIcon;
}>();
const strapiURL = useRuntimeConfig().public.twicpics.baseURL;
const img = useImage();
</script>

  <style lang="scss" scoped>
@use '$/colors.scss';

.card {
  cursor: pointer;

  position: relative;

  overflow: hidden;

  height: 248px;

  border-radius: 8px;

  &__content {
    overflow: hidden;

    width: 100%;
    height: 100%;
    padding: 20px;

    color: white;
    word-wrap: break-word;

    background: linear-gradient(360deg, rgb(0 0 0 / 80%) 0%, rgb(0 0 0 / 0%) 70%);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;

    &__icon {
      margin-bottom: 15px;
    }

    &__label {
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
      text-align: center;
    }

    &__separate {
      width: 25%;
      margin: 10px 0;
      border-bottom: 1px solid white;
    }

    &__link {
      color: colors.$primary-yellow;
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }
}
</style>

