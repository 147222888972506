<template>
  <div class="container">
    <div class="top">
      <SectionTitle class="title">{{ title }}</SectionTitle>
    </div>

    <div v-if="topThemes && topThemes.length > 0" class="themes-container">
      <div>
        <SwiperArrows v-model="swiperTop" class="arrow-left" size="small" which="left" />
        <SwiperWrapper v-model="swiperTop" :initial-slide="0" class="themes" slides-per-view="auto">
          <SwiperSlide
            v-for="theme of topThemes"
            :key="theme.id"
            class="theme"
            center-insufficient-slides="true"
          >
            <Card
              :key="theme.id"
              :label="theme.attributes.label"
              :url-background-image="
                theme.attributes.landscape?.data?.attributes.url +
                `?twic=v1/output=webp/resize=${!breakpoints.isMobile ? 400 : 248}x248`
              "
              :url-link="`/inspirations/${theme.attributes.slug}/`"
              :width="!breakpoints.isMobile ? 400 : 248"
              :is-learn-more="false"
              :icon="theme.attributes.icon"
            />
          </SwiperSlide>
        </SwiperWrapper>
        <SwiperArrows v-model="swiperTop" class="arrow-right" which="right" />
      </div>

      <SwiperPoints v-model="swiperTop" class="points" />
    </div>

    <div v-if="bottomThemes && bottomThemes.length > 0" class="themes-container bottom-themes">
      <div v-if="homepage || breakpoints.isMobile">
        <SwiperArrows v-model="swiperBottom" class="arrow-left" size="small" which="left" />
        <SwiperWrapper
          v-model="swiperBottom"
          :initial-slide="0"
          class="inspirations"
          slides-per-view="auto"
        >
          <SwiperSlide v-for="theme of bottomThemes" :key="theme.id" class="inspiration">
            <NuxtLink :to="`/inspirations/${theme.attributes.slug}/`">
              <Card
                :key="theme.id"
                :label="theme.attributes.label"
                :url-background-image="
                  theme.attributes.squared?.data?.attributes.url +
                  `?twic=v1/output=webp/resize=248x248`
                "
                :url-link="`/inspirations/${theme.attributes.slug}/`"
                :is-learn-more="false"
              />
            </NuxtLink>
          </SwiperSlide>
        </SwiperWrapper>
        <SwiperArrows v-model="swiperBottom" class="arrow-right" size="small" which="right" />
      </div>
      <SwiperPoints v-if="homepage" v-model="swiperBottom" class="points" />
      <div v-else-if="!breakpoints.isMobile" class="inspirations-block">
        <NuxtLink
          v-for="theme of bottomThemes"
          :key="theme.id"
          :to="`/inspirations/${theme.attributes.slug}/`"
          class="secondary-inspiration"
        >
          <Card
            :key="theme.id"
            :label="theme.attributes.label"
            :url-background-image="theme.attributes.squared?.data?.attributes.url"
            :url-link="`/inspirations/${theme.attributes.slug}/`"
            :is-learn-more="false"
          />
        </NuxtLink>
      </div>
    </div>
    <router-link class="link" to="/inspirations/">
      <button class="more">Voir plus de thématiques</button></router-link
    >
  </div>
</template>

<script lang="ts" setup>
import { MvTheme } from '@/lib/strapi-types/MvTheme';
import { SwiperBinding } from '@/lib/types/swiper';

defineProps<{
  title: string;
  description?: string;
  homepage?: boolean;
  topThemes?: MvTheme[];
  bottomThemes?: MvTheme[];
}>();

const swiperTop = ref<SwiperBinding>();
const swiperBottom = ref<SwiperBinding>();

const breakpoints = useBreakpoints();
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/metrics.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/custom-mixins.scss';

.container {
  flex-direction: column;
  gap: 40px;
  align-items: center;
  padding: 60px 0;

  @include breakpoints.mobile() {
    align-items: initial;
    width: initial;
    padding: 40px 0 20px;
  }

  .link {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    .more {
      @include button.outlined();
    }
  }

  .top {
    align-items: center;
    justify-content: space-between;

    .title {
      width: metrics.$inner-width;

      @include breakpoints.mobile() {
        width: initial;
        margin-left: metrics.$mobile-inner-margin;
      }
    }
  }

  .themes-container {
    flex-direction: column;
    gap: 40px;

    &.bottom-themes {
      @include breakpoints.mobile() {
        align-items: initial;
        width: 100vw;
        padding: 20px metrics.$mobile-inner-margin;
      }
    }
  }

  .themes,
  .inspirations {
    $mobile-width: 264px;

    width: 90vw;

    @include breakpoints.mobile() {
      width: 100%;
      margin: 0 0 0 metrics.$mobile-inner-margin;
    }

    &:deep(.swiper-wrapper) {
      width: 100%;

      .theme,
      .inspiration {
        cursor: pointer;

        height: 264px;
        margin-right: 12px;

        background-size: cover;
        border-radius: 8px;

        @include breakpoints.mobile() {
          background-position: center;
        }

        .inner {
          position: absolute;

          overflow: hidden;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;

          width: 100%;
          height: 100%;

          background: linear-gradient(360deg, rgb(0 0 0 / 80%) 0%, rgb(0 0 0 / 0%) 70%);
          border-radius: 8px;

          .image {
            position: absolute;
            z-index: -1;
            border-radius: 8px;
          }

          .icon {
            width: 60px;
            height: 60px;

            &:deep(svg path) {
              width: 60px;
              height: 60px;
              fill: pink;
            }
          }

          .title {
            padding: 12px 0 24px;
            font-size: 24px;
            font-weight: 900;
            color: white;

            @include breakpoints.mobile() {
              font-size: 20px;
            }
          }
        }
      }

      .inspiration {
        width: 248px;
        height: 248px;

        .inner {
          .title {
            padding: 24px;
            text-align: center;
          }
        }
      }
    }
  }

  .inspirations-block {
    @include custom-mixins.mv-grid();

    .secondary-inspiration {
      @include custom-mixins.mv-grid-element();
    }
  }

  .points {
    @include breakpoints.mobile() {
      display: none;
    }
  }

  .arrow-left,
  .arrow-right {
    @include breakpoints.mobile() {
      display: none;
    }
  }
}
</style>
