import { MvHeader } from '@/lib/strapi-types/MvHeader';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseSingle } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface UseFindHeadersProps {
  filters?: Record<string, unknown>;
  populate?: string[];
}

export async function useFindHeaders({ filters = {}, populate = [] }: UseFindHeadersProps = {}) {
  const defaultPopulate = [
    'headerBand',
    'header.mainLogo',
    'header.navButtons',
    'header.rightButtons.icon',
    'header.navThematics'
  ];
  const allPopulate = [...defaultPopulate, ...populate];

  const parsedQuery = stringify(
    {
      populate: allPopulate,
      filters,
      pagination: { start: 0, limit: 200 }
    },
    { arrayFormat: 'brackets' }
  );

  const { data } = await useFetchWithHead<Strapi4ResponseSingle<MvHeader['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.HEADER)}?${parsedQuery}`
  );
  return { headers: data.value?.data };
}
