const prefix = 'api';

enum DevBackEndpoints {
  // Data
  ARTICLES = 'mv-articles',
  ACTIVITIES = 'mv-activities',
  ACTIVITY_TAGS = 'mv-activity-tags',
  MONTHS = 'mv-months',
  COUNTRY_TAGS = 'mv-country-tags',
  CONTINENTS = 'mv-continents',
  JOURNEYS = 'mv-journeys',
  PLACES = 'mv-places',
  CATEGORIES = 'category-mvs',
  COUNTRIES = 'mv-countries',
  HEADER = 'mv-header',
  EXPERTS = 'mv-experts',
  EXPERTS_SLIDER = 'mv-experts-slider',
  SECTIONS = 'mv-sections',
  THEMES = 'mv-themes',
  FOOTER = 'mv-footer',
  GUIDE = 'mv-guides',
  GUIDE_TAG = 'mv-guide-tags',
  REGIONS = 'mv-regions',
  HOTELS = 'mv-hotels',
  PAGES = 'mv-pages',
  REVIEWS = 'mv-reviews',
  CGV = 'mv-cgvs',
  DESTNATION_REVIEWS = 'destination-reviews',

  // Plugins
  REDIRECTS = 'redirects',

  // Pages
  COMMON = 'mv-common',
  HOME_PAGE = 'mv-home-page',
  COUNTRY_PAGE = 'mv-country-page',
  COUNTRY_ACTIVITIES_PAGE = 'mv-country-activities-page',
  COUNTRY_WHERE_TO_GO_PAGE = 'mv-country-where-to-go-page',
  TAILOR_MADE_TRAVEL_PAGE = 'mv-tailor-made-travel-page',
  THEME_LIST_PAGE = 'mv-theme-list-page',
  THEME_PAGE = 'mv-theme-page',
  WHERE_TO_GO_PAGE = 'mv-where-to-go-page',
  LYON_AGENCY_PAGE = 'mv-lyon-agency-page',
  EXPERT_PAGE = 'mv-expert-page',
  EXPERT_LIST_PAGE = 'mv-expert-list-page',
  TOUR_PAGE = 'mv-tour-page',
  CONTINENT_PAGE = 'mv-continent-page',
  COUNTRY_SHEET_PAGE = 'mv-country-sheet-page',
  JOURNEY_LIST_PAGE = 'mv-journey-list-page',
  CLUB_PAGE = 'mv-club-page',
  UNSUBSCRIBE_PAGE = 'mv-unsubscribe-page'
}

export { DevBackEndpoints, prefix };
