import 'swiper/scss'
import 'swiper/scss/a11y'
import 'swiper/scss/effect-cards'
import 'swiper/scss/effect-creative'
import 'swiper/scss/effect-cube'
import 'swiper/scss/effect-fade'
import 'swiper/scss/effect-flip'
import 'swiper/scss/free-mode'
import 'swiper/scss/grid'
import 'swiper/scss/lazy'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import 'swiper/scss/scrollbar'
import 'swiper/scss/virtual'
import 'swiper/scss/zoom'
import { Swiper, SwiperSlide } from 'swiper/vue'
export default defineNuxtPlugin((nuxtApp) => {
            nuxtApp.vueApp.component('Swiper', Swiper)
            nuxtApp.vueApp.component('SwiperSlide', SwiperSlide)
          })